import React from "react";

function Today () {
    return(
        <div>
            <div class="container">
                <h3 className="todays-news">Today's News</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./todayone.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <h5 class="card-title-around">AI's Role in Reshaping the U.S. Job Market</h5>
                                    <p class="card-text-around">Artificial intelligence (AI) is no longer a futuristic concept; it’s a transformative force reshaping,</p>
                                    <a href="./todayblogone" class="btn btn-primary">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./todaytwo.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                <h5 class="card-title-around">The Rise of Green Tech in the US:</h5>
                                    <p class="card-text-around">As the world confronts climate change, the U.S. is emerging as a leader in green technology.</p>
                                    <a href="./todayblogtwo" class="btn btn-primary">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./todaythr.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                <h5 class="card-title-around">Hollywood Meets Technology: How 3D Animation...</h5>
                                    <p class="card-text-around">Hollywood has always been a pioneer in adopting technology, and 3D animation is now at the forefront.</p>
                                    <a href="./todayblogthree" class="btn btn-primary">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./trump.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                <h5 class="card-title-around">Donald Trump's Go-To Dance Move Has Invaded Sports</h5>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="./todayblogfour" class="btn btn-primary">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="view-all" href="#">View All</button>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="cont-three">
                <div className="row">
                    <div className="col-md-6 cont-three-r">
                        <h1 className="dot">.</h1>
                        <a className="history" href="#">PERSONAL HISTORY</a>
                        <br/>
                        <br/>
                        <a className="history-long" href="#">THE LONG WAY HOME AFTER A CANCELLED FLIGHT</a>
                        <br/>
                        <br/>
                        <a className="history-had" href="#">Had I poposed earlier that we invite someone stranded yo come with us to India, Hyderabad would have said no. But now there was really no way for him to back out.</a>
                        <br/>
                        <br/>
                        <a className="history-name" href="#">By David Sagar</a>
                    </div>
                    <div className="col-md-6">
                        <img className="cont-thr-img" src="./nb.png" />
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <hr/>
        </div>
    )
}

export default Today