import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Articlesone () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">The Art of Writing Captivating Articles</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./artblogone.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">In a world inundated with information, crafting an article that stands out is both a challenge and an art. Whether you're a seasoned writer or a beginner, understanding what makes an article engaging is crucial. Here are the key elements:</p>
                    <br/>
                    <h2 className="blog-title">Choose a Compelling Topic</h2>
                    <p className="blog-para-one">Your article should address a topic that resonates with your audience. Consider their interests, concerns, and questions. A strong headline is equally important—it should grab attention and entice readers to explore further.</p>
                    <p className="blog-para-one"></p>
                    <br/>
                    <h2 className="blog-title">Structure and Clarity</h2>
                    <p className="blog-para-one">A well-organized article is easier to read and understand. Use subheadings to break up content, and ensure each section flows logically into the next. Avoid jargon unless your audience is familiar with it, and prioritize clarity.</p>
                    <p className="blog-para-one">Humans are naturally drawn to stories. Incorporate anecdotes, case studies, or personal experiences to illustrate your points. Stories make your content relatable and memorable.</p>
                    <br/>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Articlesone