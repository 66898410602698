import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Reformsblogone () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">India's Push for Global Financial Reform</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./refblogone.jpg" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">In an era marked by economic uncertainty and shifting power dynamics, India has emerged as a vocal proponent of global financial reform. At the heart of India's advocacy is the need for a more equitable and inclusive global financial system that addresses the needs of developing countries.</p>
                    <br/>
                    <h2 className="blog-title">The Current Financial Landscape</h2>
                    <p className="blog-para-one">Institutions like the International Monetary Fund (IMF) and the World Bank have been instrumental in shaping global financial policies. However, their structures, predominantly influenced by Western economies, often sideline emerging markets. India argues that these institutions must reflect the contemporary economic realities, where nations like India, Brazil, and China play.</p>
                    <br/>
                    <h2 className="blog-title">India's Proposals for Reform</h2>
                    <p className="blog-para-one"><strong>Quota Revisions in IMF:</strong> India has consistently pushed for a realignment of voting rights to better represent the economic contributions of emerging markets.</p>
                    <p className="blog-para-one"><strong>Debt Sustainability Frameworks:</strong> Advocating for mechanisms that help developing nations manage debt crises without crippling austerity measures.</p>
                    <br/>
                    <h2 className="blog-title">Impact on the Global Stage</h2>
                    <p className="blog-para-one">India's efforts have resonated within forums like the G20 and BRICS, where member nations collectively emphasize the need for a fairer financial order. As the world navigates challenges like inflation, recession fears, and climate change, India's leadership in advocating reforms could reshape the global financial architecture.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reformsblogone