import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Newsblogtwo () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">The Evolution of News Delivery: From Print to Digital</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./newsblogtwo.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">The way we consume news has transformed dramatically over the years. From newspapers to smartphones, each technological advancement has shaped how information is delivered and received. Let’s explore this fascinating evolution.</p>
                    <br/>
                    <h2 className="blog-title">The Era of Print Media</h2>
                    <p className="blog-para-one">For centuries, newspapers were the primary source of news. They offered in-depth reporting and played a crucial role in educating the public and shaping opinions.</p>
                    <p className="blog-para-one">The advent of radio and television brought news into people’s homes. Real-time reporting became possible, making events more immediate and impactful.</p>
                    <br/>
                    <h2 className="blog-title">The Digital Revolution</h2>
                    <p className="blog-para-one">The internet revolutionized news delivery. Online platforms provide instant access to information, allowing readers to stay updated 24/7. Social media has further accelerated the speed at which news spreads, though it has also introduced challenges like misinformation.</p>
                    <br/>
                    <h2 className="blog-title">The Future of News</h2>
                    <p className="blog-para-one">Emerging technologies like artificial intelligence and augmented reality promise to make news more interactive and personalized. However, maintaining journalistic integrity in this fast-paced landscape remains a critical concern.</p>
                    <p className="blog-para-one">As news delivery continues to evolve, its core purpose remains the same: to inform, educate, and empower the public.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Newsblogtwo