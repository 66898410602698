import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Reformsblogthree () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">India’s Advocacy for UN Security Council Reforms</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./refblogthree.jpg" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">The United Nations Security Council (UNSC), established in 1945, remains one of the most powerful bodies in global governance. However, its structure has been criticized for being outdated and unrepresentative of today’s geopolitical realities. India’s campaign for UNSC reform is a significant step toward democratizing global decision-making.</p>
                    <br/>
                    <h2 className="blog-title">Why Reform is Necessary</h2>
                    <p className="blog-para-one">1. Geopolitical Shifts: The global power balance has shifted significantly since the mid-20th century, with emerging economies like India playing crucial roles in global affairs.</p>
                    <p className="blog-para-one">2. Underrepresentation: Africa and Latin America have no permanent representation, while large democracies like India remain excluded</p>
                    <br/>
                    <h2 className="blog-title">India’s Case for Permanent Membership</h2>
                    <p className="blog-para-one">1. Global Contributions: India is the world’s largest democracy and a major contributor to UN peacekeeping missions.</p>
                    <p className="blog-para-one">2. Economic Strength: As the fifth-largest economy, India’s economic contributions underscore its importance on the global stage.</p>
                    <br/>
                    <h2 className="blog-title">Challenges to Reform</h2>
                    <p className="blog-para-one">Resistance from current permanent members, particularly those with veto power, has stalled progress. However, India’s alliance with the G4 (Germany, Japan, and Brazil) and its emphasis on equitable representation continue to drive the conversation forward.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reformsblogthree