import './App.css';
import Home from './home';
import { BrowserRouter, Route } from 'react-router';
import { Routes, Switch } from 'react-router';
import Education from './education';
import Learning from './learning';
import Humor from './humor';
import News from './news';
import Health from './health';
import Polls from './polls';
import Reforms from './reforms';
import Visions from './visions';
import Guidens from './guidens';
import About from './about';
import Articles from './articles';
import Role from './role';
import Blogone from './education blogs/blogone';
import Blogtwo from './education blogs/blogtwo';
import Blogthree from './education blogs/blogthree';
import Blogfour from './education blogs/blogfour';
import Learningblogone from './learning blogs/learblogone';
import Learningblogtwo from './learning blogs/learblogtwo';
import Learningblogthree from './learning blogs/learblogthree';
import Learningblogfour from './learning blogs/learblogfour';
import Newsblogone from './news blogs/newsone';
import Newsblogtwo from './news blogs/newstwo';
import Newsblogthree from './news blogs/newsthree';
import Newsblogfour from './news blogs/newsfour';
import Healthblogone from './Health Blogs/healthone';
import Healthblogtwo from './Health Blogs/healthtwo';
import Healthblogthree from './Health Blogs/healththree';
import Healthblogfour from './Health Blogs/healthfour';
import Articlesone from './articles blogs/articlesone';
import Articlestwo from './articles blogs/articlestwo';
import Articlesthree from './articles blogs/articlesthree';
import Articlesfour from './articles blogs/articlesfour';
import Humorblogone from './humorblogone';
import Reformsblogone from './reforms blogs/reformsone';
import Reformsblogtwo from './reforms blogs/reformstwo';
import Reformsblogthree from './reforms blogs/reformsthree';
import Reformsblogfour from './reforms blogs/reformsfour';
import Contact from './contact';
import Privacypolicy from './privacypolicy';
import Privacyright from './privacyright';
import Todayblogone from './Today blogs/todayblogone';
import Todayblogtwo from './Today blogs/todayblogtwo';
import Todayblogthree from './Today blogs/todayblogthree';
import Todayblogfour from './Today blogs/todayblogfour';
import Coming from './coming';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route exact path="/*" element={<Home />} />
          <Route exact path="home/*" element={<Home />} />
          <Route path="education/*" element={<Education />} />
          <Route exact path="learning/*" element={<Learning />} />
          <Route exact path="humor/*" element={<Humor />} />
          <Route exact path="news/*" element={<News />} />
          <Route exact path="health/*" element={<Health />} />
          <Route exact path="polls/*" element={<Polls />} />
          <Route exact path="reforms/*" element={<Reforms />} />
          <Route exact path="visions/*" element={<Visions />} />
          <Route exact path="guidens/*" element={<Guidens />} />
          <Route exact path="articles/*" element={<Articles />} />
          <Route exact path="role/*" element={<Role />} />
          <Route exact path="about/*" element={<About />} />

          <Route exact path="blogone/*" element={<Blogone />} />
          <Route exact path="blogtwo/*" element={<Blogtwo />} />
          <Route exact path="blogthree/*" element={<Blogthree />} />
          <Route exact path="blogfour/*" element={<Blogfour />} />

          <Route exact path="learblogone/*" element={<Learningblogone />} />
          <Route exact path="learblogtwo/*" element={<Learningblogtwo />} />
          <Route exact path="learblogthree/*" element={<Learningblogthree />} />
          <Route exact path="learblogfour/*" element={<Learningblogfour />} />

          <Route exact path="newsone/*" element={<Newsblogone />} />
          <Route exact path="newstwo/*" element={<Newsblogtwo />} />
          <Route exact path="newsthree/*" element={<Newsblogthree />} />
          <Route exact path="newsfour/*" element={<Newsblogfour />} />

          <Route exact path="healthone/*" element={<Healthblogone />} />
          <Route exact path="healthtwo/*" element={<Healthblogtwo />} />
          <Route exact path="healththree/*" element={<Healthblogthree />} />
          <Route exact path="healthfour/*" element={<Healthblogfour />} />

          <Route exact path="articlesone/*" element={<Articlesone />} />
          <Route exact path="articlestwo/*" element={<Articlestwo />} />
          <Route exact path="articlesthree/*" element={<Articlesthree />} />
          <Route exact path="articlesfour/*" element={<Articlesfour />} />

          <Route exact path="humorblogone/*" element={<Humorblogone />} />

          <Route exact path="reformsone/*" element={<Reformsblogone />} />
          <Route exact path="reformstwo/*" element={<Reformsblogtwo />} />
          <Route exact path="reformsthree/*" element={<Reformsblogthree />} />
          <Route exact path="reformsfour/*" element={<Reformsblogfour />} />

          <Route exact path="todayblogone/*" element={<Todayblogone />} />
          <Route exact path="todayblogtwo/*" element={<Todayblogtwo />} />
          <Route exact path="todayblogthree/*" element={<Todayblogthree />} />
          <Route exact path="todayblogfour/*" element={<Todayblogfour />} />

          <Route exact path="contact/*" element={<Contact />} />
          <Route exact path="Privacypolicy/*" element={<Privacypolicy />} />
          <Route exact path="Privacyright/*" element={<Privacyright />} />
          <Route exact path="coming/*" element={<Coming />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
