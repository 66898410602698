import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Polls () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Opinion Polls</p>
                <p className="vis-para">Quest for pulse of people on burning issues of social ,political and economy</p>
            </div>
            
            <div>
                <p className="fourofour">404</p>
            </div>

            </div>
            <Footer />
        </div>
    )
}

export default Polls