import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Health () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Health</p>
                <p className="vis-para">Quest for mental and Physical health, life care   and wellness , Precautions, preventive and curing  techniques.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Importance of Preventative Healthcare.</p>
                                <a className="edu-para-one" href="#">Preventative healthcare is a cornerstone of a healthy life. By taking proactive steps to maintain your well-being, you can avoid many illnesses and improve your quality of life. Here’s why it matters:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./healthone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./healthblogone.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">Mental Health Matters: Breaking the Stigma.</p>
                                <a className="edu-para-one" href="#">Mental health is as important as physical health, yet it often doesn’t receive the attention it deserves. Breaking the stigma surrounding mental health is essential for creating a supportive and understanding society.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./healthtwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./healthblogtwo.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Connection Between Nutrition and Health</p>
                                <a className="edu-para-one" href="#">Nutrition plays a vital role in maintaining overall health. What we eat directly impacts our physical and mental well-being. Here’s why a balanced diet matters:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./healththree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./healthblogthree.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">What Can Stop the Cycle of Education in Ukraine?</p>
                                <a className="edu-para-one" href="#">As the Biden Administration approves new weaponry for Ukrainian forces, Putin has invoked Russia’s nuclear arsenal, but neither move is likely to significantly alter the trajectory of the war.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./healthfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./healthblogfour.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Health