import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Blogthree () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">How to Foster a Lifelong Love of Learning in Students</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./edublogthree.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Education shouldn’t stop at the classroom—it should ignite a passion for lifelong learning. But how can parents and educators nurture this mindset in student.</p>
                    <br/>
                    <h2 className="blog-title">Encourage Curiosity</h2>
                    <p className="blog-para-one">Curiosity is the foundation of learning. Foster it by encouraging students to ask questions and explore topics that interest them. Show enthusiasm for their discoveries and provide resources to support their curiosity.</p>
                    <p className="blog-para-one">Incorporate games, hands-on activities, and interactive tools into lessons. When students enjoy the process of learning, they are more likely to seek knowledge outside the classroom.</p>
                    <br/>
                    <h2 className="blog-title">Provide Autonomy</h2>
                    <p className="blog-para-one">Give students a say in their learning journey. Allow them to choose projects, topics, or books that resonate with their interests. Autonomy boosts motivation and engagement.</p>
                    <p className="blog-para-one">Recognize and celebrate the effort students put into their learning. This builds resilience and encourages them to persevere, even when faced with challenges.</p>
                    <br/>
                    <h2 className="blog-title">Create a Supportive Environment</h2>
                    <p className="blog-para-one">A safe and supportive learning environment fosters confidence. Encourage students to share their thoughts, make mistakes, and learn from them without fear of judgment.</p>
                    <p className="blog-para-one">Be a role model by demonstrating your own love for learning. Share your experiences, show curiosity, and engage in new activities to inspire students to follow suit.</p>
                    <p className="blog-para-one">By implementing these strategies, we can cultivate a generation of lifelong learners who approach the world with curiosity, creativity, and confidence.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Blogthree