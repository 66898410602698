import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Guidens () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-edu-banner">
                <p className="gui-tit">Videos</p>
                <p className="vis-para">Quest for visual content  focusing on motivational and educational content.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">One of The Greatest Speeches Ever by President Obama.</p>
                                <a className="edu-para-one" href="#">Barack Obama the 44th president of the United States. Listen to the end of this video where President Obama shares his most valuable piece of advice for How To Succeed In Life by overcoming all barriers. His GREATEST Life.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Barak Obama</a> 
                                <br/>
                                <a className="edu-date-one" href="#">August 26, 2022</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <iframe className="blog-one-edu" src="https://www.youtube.com/embed/NaaSpRMBHjg" title="One of The Greatest Speeches Ever by President Obama | Best Eye Opening Speech" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">One of the Greatest Speeches Ever | Steve Jobs</p>
                                <a className="edu-para-one" href="#">Steve Jobs delivers an inspirational speech. Listen to the end for the most life changing quote of all-time. Don't let anyone ever tell you that you cannot achieve your dreams.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Steve Jobs</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <iframe className="blog-one-edu" src="https://www.youtube.com/embed/Tuw8hxrFBH8" title="One of the Greatest Speeches Ever | Steve Jobs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 
      
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Guidens