import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Healthblogone () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">The Importance of Preventative Healthcare</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./healthblogone.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Preventative healthcare is a cornerstone of a healthy life. By taking proactive steps to maintain your well-being, you can avoid many illnesses and improve your quality of life. Here’s why it matters:</p>
                    <br/>
                    <h2 className="blog-title">Early Detection Saves Lives</h2>
                    <p className="blog-para-one">Regular screenings and check-ups can detect diseases early when they are most treatable. For example, mammograms and colonoscopies are crucial for early cancer detection.</p>
                    <p className="blog-para-one">Preventing illness is often more cost-effective than treating it. Vaccinations, for instance, can save thousands of dollars in medical expenses.</p>
                    <br/>
                    <h2 className="blog-title">Promotes Long-Term Well-Being</h2>
                    <p className="blog-para-one">Adopting healthy habits, such as a balanced diet, regular exercise, and sufficient sleep, can enhance your overall health and prevent chronic diseases like diabetes and heart disease.</p>
                    <br/>
                    <h2 className="blog-title">Encourages Health Education</h2>
                    <p className="blog-para-one">Preventative care fosters awareness about potential health risks, empowering individuals to make informed decisions about their lifestyles.</p>
                    <p className="blog-para-one">By prioritizing preventative healthcare, you can take control of your health and enjoy a longer, healthier life.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Healthblogone