import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Articles () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Articles</p>
                <p className="vis-para">Quest for insights and conclusions  on trending subjects and events.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Art of Writing Captivating Articles.</p>
                                <a className="edu-para-one" href="#">In a world inundated with information, crafting an article that stands out is both a challenge and an art. Whether you're a seasoned writer or a beginner, understanding what makes an article engaging is crucial.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./articlesone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./artblogone.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Role of Articles in Building a Knowledge Base.</p>
                                <a className="edu-para-one" href="#">Articles play a pivotal role in education, marketing, and personal development. They serve as a cornerstone for building a knowledge base that benefits both individuals and organizations.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./articlestwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./artblogtwo.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">How to Conduct Effective Research for Articles.</p>
                                <a className="edu-para-one" href="#">Good research is the backbone of great articles. Whether you’re writing a technical report or a lifestyle piece, thorough research ensures accuracy and depth. Here’s a step-by-step guide:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./articlesthree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./artblogthree.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">Exercise: The Key to a Healthy Life.</p>
                                <a className="edu-para-one" href="#">Exercise is a powerful tool for improving physical and mental health. From reducing stress to strengthening the heart, regular physical activity offers countless benefits:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./articlesfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./artblogfour.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Articles