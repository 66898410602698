import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Reformsblogtwo () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">India’s Role in Global Climate Policy Reform</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./refblogtwo.jpg" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Climate change is a pressing global issue, and India has positioned itself as a key player in driving global climate policy reform. From ambitious renewable energy targets to advocating for climate justice, India is influencing the global narrative on sustainable development.</p>
                    <br/>
                    <h2 className="blog-title">India's Commitment to Renewable Energy</h2>
                    <p className="blog-para-one">India’s National Solar Mission aims to make the country a global hub for solar energy. With a target of 500 GW of renewable energy by 2030, India is setting an example for other developing nations.</p>
                    <p className="blog-para-one"></p>
                    <br/>
                    <h2 className="blog-title">Advocating Climate Justice</h2>
                    <p className="blog-para-one">India has been vocal about the principle of "Common but Differentiated Responsibilities" (CBDR). This principle emphasizes that developed nations, being historically responsible for the majority of greenhouse gas emissions, should bear a larger share of the burden in combating climate change.</p>
                    <p className="blog-para-one">nternational Solar Alliance (ISA): India’s initiative to foster cooperation among solar-resource-rich countries has gained global traction.</p>
                    <br/>
                    <h2 className="blog-title">Challenges and Opportunities</h2>
                    <p className="blog-para-one">While India's stance has garnered global attention, challenges like balancing economic growth with environmental sustainability remain. However, with continued international collaboration, India’s leadership could pave the way for transformative climate policies.</p>
                    <p className="blog-para-one">Climate Finance Advocacy: India calls for developed nations to fulfill their $100 billion annual commitment to support developing nations in their climate efforts.</p>
                    <p className="blog-para-one"></p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reformsblogtwo