import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";


function Coming () {
    return(
        <div>
            <Navbar/>
            <div className="four-o-four">
                <p>.</p>
            </div>
            <br/>
            <Footer />
        </div>
    )
}

export default Coming