import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Healthblogthree () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">The Connection Between Nutrition and Health</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./healthblogthree.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Nutrition plays a vital role in maintaining overall health. What we eat directly impacts our physical and mental well-being. Here’s why a balanced diet matters:</p>
                    <br/>
                    <h2 className="blog-title">Fuels Your Body</h2>
                    <p className="blog-para-one">The nutrients in food provide energy for daily activities and support bodily functions. Carbohydrates, proteins, and fats are essential macronutrients, while vitamins and minerals are crucial for overall health.</p>
                    <br/>
                    <h2 className="blog-title">Boosts Immunity</h2>
                    <p className="blog-para-one">Foods rich in antioxidants, such as fruits and vegetables, strengthen the immune system and help fight infections and diseases.</p>
                    <br/>
                    <h2 className="blog-title">Supports Mental Health</h2>
                    <p className="blog-para-one">Certain nutrients, like omega-3 fatty acids and B vitamins, are linked to improved mood and cognitive function. A poor diet, on the other hand, can contribute to depression and anxiety.</p>
                    <p className="blog-para-one">A balanced diet can reduce the risk of conditions like obesity, heart disease, and type 2 diabetes. Consuming whole grains, lean proteins, and healthy fats is key to long-term health.</p>
                    <p className="blog-para-one">By making informed dietary choices, you can enjoy better health and a higher quality of life.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Healthblogthree