import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Privacyright () {
    return(
        <div>
            <Navbar/>
            <div className="Privacy-Policy-page">
                <h1 classname="pp-pp-pp">Privacy Rights</h1>
                <br/>Effective Date: 15 Jan 2025
                <br/>Last Updated: 22 Jan 2025

                <br/><br/>At Times Quest, we are committed to ensuring that your privacy rights are respected and protected. This page outlines the rights you have regarding your personal data and how you can exercise them.

                <br/><br/>1. Your Privacy Rights
                <br/>Depending on your location and applicable privacy laws, you may have the following rights:

                <br/><br/>a. Right to Access
                <br/>You have the right to request access to the personal data we collect about you and to receive details on how it is used, shared, and stored.

                <br/><br/>b. Right to Rectification
                <br/>If your personal information is inaccurate or incomplete, you have the right to request corrections or updates.

                <br/><br/>c. Right to Erasure (Right to Be Forgotten)
                <br/>You can request that we delete your personal data under certain circumstances, such as if it is no longer needed for the purposes for which it was collected.

                <br/><br/>d. Right to Data Portability
                <br/>You have the right to request that your personal data be provided to you in a commonly used, machine-readable format or transferred to another data controller where technically feasible.

                <br/><br/>e. Right to Restrict Processing
                <br/>You can request that we limit how we process your personal data, for example, if you contest its accuracy or object to its processing.

                <br/><br/>f. Right to Object
                <br/>You may object to the processing of your personal data for certain purposes, such as direct marketing, at any time.

                <br/><br/>g. Right to Withdraw Consent
                <br/>If you have provided consent for specific data processing activities, you can withdraw that consent at any time.

                <br/><br/>h. Right to Lodge a Complaint
                <br/>If you believe we have violated your privacy rights, you have the right to lodge a complaint with a relevant supervisory authority, such as a data protection agency in your jurisdiction.

                <br/><br/>2. How to Exercise Your Rights
                <br/>If you wish to exercise any of the above rights, please contact us using the details provided below:

                <br/><br/>Contact Information:
                <br/>Times Quest
                <br/>Email: <a href="#">contact@timesquest.com</a>

                <br/>When submitting a request:

                <br/>Provide sufficient information to verify your identity (e.g., your name, email address, or other identifying details).
                <br/>Clearly describe the nature of your request.
                <br/>We will respond to your request within [30 days] (or the timeframe required by applicable law).

                <br/><br/>3. Additional Information for California Residents (CCPA)
                <br/>If you are a California resident, the California Consumer Privacy Act (CCPA) provides additional rights, including:

                <br/>The right to know the categories of personal information collected and the purposes for which it is used.
                <br/>The right to opt out of the sale of personal information (if applicable).
                <br/>To opt out of data sales (if applicable), please use the following link: [Insert Link to Opt-Out Form].

                <br/><br/>4. Additional Information for EU/EEA Residents (GDPR)
                <br/>If you are located in the European Union or European Economic Area, you have enhanced rights under the General Data Protection Regulation (GDPR). These include:

                <br/>The right to know whether we process your data based on legitimate interests or consent.
                <br/>The right to request detailed information about data transfers outside the EU/EEA.
                <br/>For inquiries related to GDPR rights, contact our Data Protection Officer (DPO) at [Insert DPO Contact Email].

                <br/><br/>5. Data Retention
                <br/>We retain your personal data only as long as necessary to fulfill the purposes outlined in our Privacy Policy or comply with legal obligations. Once retention is no longer required, we securely delete or anonymize your data.

                <br/><br/>6. Updates to Privacy Rights
                <br/>This Privacy Rights page may be updated periodically to reflect changes in laws or our practices. Please review it regularly to stay informed about your rights.

                <br/>By using the Times Quest blog, you acknowledge that you understand and agree to these privacy rights. If you have any concerns or require further assistance, feel free to contact us at any time.
            </div>
            <Footer />
        </div>
    )
}

export default Privacyright