import React from "react";

function Cor () {
    return(
        <div>
        <div className="cont-main-one">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="home-cont-tit-one">Welcome to our page</h1>
                    <p className="wel-text">Welcome to our blog, where ideas come alive and stories inspire. Here, you’ll find a curated collection of articles spanning topics that matter most to our readers—from insightful guides to thought-provoking opinions. Join us on this journey of exploration and discovery, and let your curiosity lead the way!</p>
                    <div className="row inside-cont">
                        <div className="col-md-8">
                            <h3 className="main-one-title">The Explosion of Matt Gaetz Early Lessons in Trump 2.0</h3>
                            <br/>
                            <p className="main-one-title-para">That was fast. Barely two weeks after winning reëlection, Donald Trump has already so thoroughly owned the news cycle that I’m not sure anyone even recalls that Joe Biden is still President. (It was his eighty-second birthday on Wednesday, by the way.) One of the themes of this year’s campaign was the apparent mass amnesia among many Americans of just what the Trump Presidency was like. Every day since Trump won has been a crash course in remembering: </p>
                        </div>
                        <div className="col-md-3">
                            <img className="main-one-img" src="./one.png" />
                        </div>
                    </div>
                </div>
                <div className="col-md-3 side-car">
                <div class="card side-s">
                    <img src="./two.jpg" class="card-img-top" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title-around">How we’re approaching AI-generated.</h5>
                        <p class="card-text-around">AI-generated content is here now, and it’s important to start wrestling with that impact now too.</p>
                        <a href="./blogone" class="btn btn-primary">Know more...</a>
                    </div>
                </div>
                </div>
            </div>
            <hr/>
        </div>
        
        </div>
    )
}

export default Cor