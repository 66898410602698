import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Contact () {
    return(
        <div>
            <Navbar/>
            <div className="contact-page">
                <div className="row">
                    <div className="col-md-5">
                        <h1 className="contact-us">Contact</h1>
                        <p className="contact-us-para">Need to get in touch with us? Either fill out the form with your enquiry or Find the attached email: <a href="">contact@timesquest.com</a> you'd like to contact.</p>
                    </div>
                    <div className="col-md-5 contact-form">
                        <h1 className="name">TELL US YOUR NAME:</h1>
                        <input className="first-name" type="text" id="fname" name="fname" placeholder="Name:" /> <br/>
                        <h1 className="name">ENTER YOUR EMAIL:</h1>
                        <input className="email-hol" type="email" id="email" placeholder="@gmail.com" />
                        <h1 className="name">MESSAGE:</h1>
                        <input className="msg" type="text" placeholder="Write us a message..." />
                        <br/>
                        <button className="button-contone">Send</button>
                    </div>
                </div>
                
            </div>
            <Footer />
        </div>
    )
}

export default Contact