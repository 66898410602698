import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Humorblogone () {
    return (
        <div>
            <Navbar />
                <h1 className="humor-tit-one-blog">God and Human Conversation – Part 1: Exploring the Divine and Human Connection</h1>
                <div className="c">
                <video className="humor-video" controls>
                    <source src="mov_b.mp4" type="video/mp4"/>
                </video>

            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one"><strong>Welcome to Part 1</strong> of this thought-provoking video series, God and Human Conversation. In this video, we delve into the intriguing dialogue between a human and a divine presence, exploring the eternal question: How does a divine being hear and grant the countless wishes of billions of humans?</p>
                    <p className="blog-para-one">Watch as the conversation unfolds, with deep insights about human hopes, dreams, and the unseen connection between us and the divine. This video offers a unique perspective on the nature of divine grace and the way wishes are granted, blending spirituality with curiosity and humility.</p>
                    <br/>
                    <h2 className="blog-title">Key Themes Covered in Part 1:</h2>
                    <p className="blog-para-one"><strong>Human (hesitant, thoughtful):</strong>
“God… are you really there? I mean, I’ve prayed to you so many times, but... sometimes it feels like you’re not listening.”<br/><br/>
<strong>God (playfully):</strong>
“Why wouldn’t I? You’ve been yelling into the void for a while now. I figured I’d save your neighbors the trouble.”<br/><br/>
<strong>Human (laughing nervously):</strong>
“Fair. Well, uh... since you’re here, I have to ask—how do you handle all the wishes? There’s, like, 8 billion people on Earth, and everyone wants something. Do you just have, like, a divine to-do list?”<br/><br/>
<strong>God (chuckling):</strong>
“More like a cosmic inbox. It’s... extensive. But don’t worry, I’ve got the best filtering system in existence.”<br/><br/>
<strong>Human (grinning):</strong>
“Filtering system? Like spam filters? What gets flagged?”<br/><br/>
<strong>God (matter-of-factly):</strong>
“Oh, you know, the usual. ‘Make my ex regret dumping me.’ ‘Help me win the lottery.’ And my favorite: ‘Turn my boss into a frog.’ Those go straight to the junk folder.”<br/><br/>
<strong>Human (laughing):</strong>
“Okay, but seriously—how do you decide whose wishes to grant? Is it like, the squeakiest wheel gets the grease?”<br/><br/>
<strong>God (wise tone):</strong>
“No, it’s not about who asks the loudest. I look at intent. Is the wish rooted in love, kindness, or growth? Or is it just... greed?”<br/><br/>
<strong>Human (teasing):</strong>
“So... no billion-dollar checks out of the blue, huh?”<br/><br/>
<strong>God:</strong>
“Not unless you plan to donate it to charity. And even then, it’s a maybe.”<br/><br/>

</p>
                    <br/>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Humorblogone