import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Privacypolicy () {
    return(
        <div>
            <Navbar/>
            <div className="Privacy-Policy-page">
                <h1 classname="pp-pp-pp">Privacy Policy</h1>
                <br/>Effective Date: 15 Jan 2025 <br/>
                Last Updated: 22 Jan 2025<br/><br/>

                <br/>At Times Quest, we value your privacy and are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our blog page.

                <br/><br/>1. Information We Collect
                <br/>We may collect the following types of information:

                <br/><br/>Personal Information: Name, email address, or other information you voluntarily provide through contact forms, comments, or newsletter subscriptions.
                <br/>Non-Personal Information: Browser type, IP address, device information, and pages visited on our site, collected automatically through cookies or similar technologies.
                <br/><br/>2. How We Use Your Information
                <br/>We use the information we collect for the following purposes:

                <br/>To improve the content and functionality of our blog.
                <br/>To respond to your inquiries or comments.
                <br/>To send newsletters or updates if you have subscribed.
                <br/>To analyze site usage and enhance user experience.
                <br/><br/>3. Cookies and Tracking Technologies
                <br/>Our website uses cookies and similar tracking technologies to:

                <br/><br/>Understand user preferences and improve website performance.
                <br/>Track website analytics (e.g., using Google Analytics).
                <br/>You can manage or disable cookies through your browser settings. Please note that some features of the website may not function properly if cookies are disabled.

                <br/><br/>4. Sharing Your Information
                <br/>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted service providers who help us operate our blog, provided they agree to keep this information confidential.

                <br/><br/>5. Third-Party Links
                <br/>Our blog may include links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of these sites before providing your personal information.

                <br/><br/>6. Data Security
                <br/>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet is entirely secure, and we cannot guarantee absolute security.

                <br/><br/>7. Your Rights
                <br/>Depending on your location, you may have certain rights under applicable privacy laws, including:

                <br/><br/>Accessing the personal information we hold about you.
                <br/>Requesting corrections to inaccurate or incomplete information.
                <br/>Requesting the deletion of your personal data.
                <br/>Withdrawing consent for specific processing activities.
                <br/>To exercise your rights, please contact us using the details below.

                <br/><br/>8. Children’s Privacy
                <br/>Our blog is not intended for children under the age of 13, and we do not knowingly collect personal information from children.

                <br/><br/>9. Changes to This Privacy Policy
                <br/>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated version will be posted on this page with the revised effective date.

                <br/><br/>10. Contact Us
                <br/>If you have any questions or concerns about this Privacy Policy or how we handle your information, please contact us at:

                <br/><br/>Times Quest
                <br/>Email: <a href="#">contact@timesquest.com</a>


            </div>
            <Footer />
        </div>
    )
}

export default Privacypolicy