import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Reformsblogfour () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">India’s Vision for Trade and Economic Reforms</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./refblogfour.jpg" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">As globalization evolves, India is championing trade and economic reforms that promote fairness and inclusivity. From advocating for equitable trade agreements to enhancing global supply chains, India is shaping the future of international trade.</p>
                    <br/>
                    <h2 className="blog-title">The Shift in Global Trade Dynamics</h2>
                    <p className="blog-para-one">Traditional trade agreements often favored developed nations, marginalizing the interests of developing economies. India has consistently called for reforms that address these disparities, ensuring a level playing field.</p>
                    <p className="blog-para-one"></p>
                    <br/>
                    <h2 className="blog-title">India’s Key Proposals</h2>
                    <p className="blog-para-one">1. Reforming WTO: India emphasizes the need for the World Trade Organization (WTO) to prioritize issues like agricultural subsidies, which are critical for developing nations.</p>
                    <p className="blog-para-one">2. Digital Economy Frameworks: With the rise of e-commerce and digital trade, India advocates for global regulations that protect data sovereignty while promoting innovation.</p>
                    <p className="blog-para-one">3. Regional Trade Alliances: India’s active participation in forums like RCEP and SAARC reflects its commitment to regional economic integration.</p>
                    <br/>
                    <h2 className="blog-title">Implications for Global Trade</h2>
                    <p className="blog-para-one">India’s stance has encouraged a broader discussion on creating trade policies that are inclusive and sustainable. As global supply chains face disruptions, India’s advocacy for resilient and equitable systems is more relevant than </p>
                    <p className="blog-para-one">In conclusion, India’s push for global reforms—whether in finance, climate, governance, or trade—underscores its vision for a more equitable and sustainable world order. By championing the interests of developing nations, India is not just reshaping global policies but also inspiring a new era of international cooperation.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reformsblogfour