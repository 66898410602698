import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function News () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">News</p>
                <p className="vis-para">Quest for burning topics and events on social, business and politics.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">Staying Updated in a Fast-Paced World: Why News Matters</p>
                                <a className="edu-para-one" href="#">In today’s rapidly changing world, staying informed is more important than ever. News provides a window into current events, shaping our understanding of the world and our place within it.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./newsone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./newsblogone.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Evolution of News Delivery: From Print to Digital</p>
                                <a className="edu-para-one" href="#">The way we consume news has transformed dramatically over the years. From newspapers to smartphones, each technological advancement has shaped how information is delivered and received. Let’s explore this fascinating evolution.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./newstwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./newsblogtwo.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">How to Identify Fake News and Misinformation.</p>
                                <a className="edu-para-one" href="#">In the age of information, not all news is created equal. Fake news and misinformation can spread like wildfire, leading to confusion and mistrust. Here’s how to identify and combat false information:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./newsthree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./newsblogthree.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Role of Technology in Modern Journalism.</p>
                                <a className="edu-para-one" href="#">Technology has revolutionized journalism, changing how stories are reported, distributed, and consumed. Let’s explore the key ways technology is shaping modern journalism:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./newsfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./newsblogfour.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default News