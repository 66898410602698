import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Humor () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Humor</p>
                <p className="vis-para">Quest for Humor , Comics and cartoons</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">God and Human Conversation – Part 1:</p>
                                <a className="edu-para-one" href="#">Welcome to Part 1 of this thought-provoking video series, God and Human Conversation. In this video, we delve into the intriguing dialogue between a human and a divine presence, exploring the eternal question:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By BSN Reddy</a> 
                                <br/>
                                <a className="edu-date-one" href="#">January 11, 2025</a> <a className="edu-link-one" href="./humorblogone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./pic.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        
                    </div>

                    <div className="col-md-4">
                        .
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Humor