import React from "react";

function Draw () {
    return(
        <div>
            <div className="draw-cont">
                <div className="row">
                    <div className="col draw-contone">
                        <p className="draw-li" href="#">ON AND Off THE AVENUE</p>
                        <a className="draw-gift" href="#">A GIFT GUIDE FULL OF LITTLE TREASURES</a>
                        <br/>
                        <a className="draw-id" href="#">Ideas for holiday shopping that makes life a litle easier or just more sparkly.</a>
                        <br/>
                        <br/>
                        <a className="draw-name" href="#">By Rachel Syme</a>
                    </div>
                    <div className="col">
                        <img className="draw-img" src="./draw.png" />
                    </div>
                </div>
            </div>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">ARTICLES</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./artblogone.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">ARTICLES - DAY 1</p>
                                    <a class="card-title-around" href="#">The Art of Writing Captivating Articles.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">In a world inundated with information, crafting an article that stands out is both a challenge...</p>
                                    <a className="home-read-more-link" href="./articlesone" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">ARTICLES - DAY 2</p>
                                    <a class="card-title-around" href="#">The Role of Articles in Building a Knowledge Base.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Articles play a pivotal role in education, marketing, and personal development.</p>
                                    <a className="home-read-more-link" href="./articlestwo" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three-on" src="./artblogthree.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">ARTICLES - DAY 3</p>
                                    <a class="card-title-around" href="#">How to Conduct Effective Research for Articles.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Good research is the backbone of great articles. Whether you’re writing a technical report or a lifestyle</p>
                                    <a className="home-read-more-link" href="./articlesthree" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./artblogfour.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">ARTICLES - DAY 4</p>
                                    <a class="card-title-around" href="#">Exercise: The Key to a Healthy Life.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Exercise is a powerful tool for improving physical and mental health. From reducing stress to...</p>
                                    <a className="home-read-more-link" href="./articlesfour" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <a href="./articles"><button className="view-all">View All</button></a>
            </div>
        </div>
    )
}

export default Draw