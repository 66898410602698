import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Education () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Education</p>
                <p className="vis-para">Quest for knowledge, wisdom and transformation.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="./blogone">The State of Healthcare in Andhra Pradesh:</p>
                                <a className="edu-para-one" href="./blogone">As Andhra Pradesh gears up for the 2024 elections, healthcare has emerged as a pivotal issue, reflecting both the challenges and achievements of the state’s governance over the past years.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./blogone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./edublogone.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Future of Education: How Technology is Shaping the Classroom:</p>
                                <a className="edu-para-one" href="#">The education sector is undergoing a transformative shift, fueled by the rapid advancements in technology. Traditional classrooms, once dominated by chalkboards and textbooks</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./blogtwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./edublogtwo.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">How to Foster a Lifelong Love of Learning in Students.</p>
                                <a className="edu-para-one" href="#">Education shouldn’t stop at the classroom—it should ignite a passion for lifelong learning. But how can parents and educators nurture this mindset in student.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./blogthree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./edublogthree.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Role of Mental Health in Academic Success.</p>
                                <a className="edu-para-one" href="#">Mental health plays a critical role in a student’s academic performance and overall well-being. Yet, it is often overlooked in traditional education systems. Here’s why mental health matters and how we can support it.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./blogfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./edublogfour.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Education