import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Healthblogtwo () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">Mental Health Matters: Breaking the Stigma</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./healthblogtwo.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Mental health is as important as physical health, yet it often doesn’t receive the attention it deserves. Breaking the stigma surrounding mental health is essential for creating a supportive and understanding society.</p>
                    <br/>
                    <h2 className="blog-title">The Impact of Stigma</h2>
                    <p className="blog-para-one">Stigma can discourage individuals from seeking help, leading to untreated conditions and worsening symptoms. It perpetuates myths and misconceptions about mental health disorders.</p>
                    <br/>
                    <h2 className="blog-title">The Role of Education</h2>
                    <p className="blog-para-one">Educating the public about mental health can dispel myths and promote understanding. Awareness campaigns, workshops, and open discussions can foster a culture of acceptance.</p>
                    <br/>
                    <h2 className="blog-title">Seeking Help is Strength</h2>
                    <p className="blog-para-one">Seeking therapy or counseling is a sign of strength, not weakness. Professional support can provide the tools needed to manage mental health challenges effectively.</p>
                    <p className="blog-para-one">Being a good listener and offering nonjudgmental support can make a significant difference in someone’s mental health journey. Encouraging them to seek help when needed is equally important.</p>
                    <p className="blog-para-one">By addressing mental health openly and compassionately, we can create a society where everyone feels valued and supported.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Healthblogtwo