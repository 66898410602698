import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Articlesfour () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">Exercise: The Key to a Healthy Life</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./artblogfour.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Exercise is a powerful tool for improving physical and mental health. From reducing stress to strengthening the heart, regular physical activity offers countless benefits:</p>
                    <br/>
                    <h2 className="blog-title">Improves Physical Fitness</h2>
                    <p className="blog-para-one">Exercise enhances cardiovascular health, builds muscle strength, and increases flexibility. Activities like jogging, swimming, and yoga cater to different fitness levels and goals.</p>
                    <br/>
                    <h2 className="blog-title">Reduces Stress and Anxiety</h2>
                    <p className="blog-para-one">Physical activity releases endorphins, which improve mood and reduce stress. Even a short walk can have a positive impact on mental well-being.</p>
                    <br/>
                    <h2 className="blog-title">Aids Weight Management</h2>
                    <p className="blog-para-one">Combining regular exercise with a healthy diet is the most effective way to achieve and maintain a healthy weight.</p>
                    <p className="blog-para-one">Studies show that regular exercise can extend lifespan and improve the quality of life in later years.</p>
                    <p className="blog-para-one">Whether it’s a daily workout or a weekend hike, incorporating exercise into your routine is one of the best investments you can make in your health.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Articlesfour