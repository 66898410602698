import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Blogfour () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">The Role of Mental Health in Academic Success</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./edublogfour.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">Mental health plays a critical role in a student’s academic performance and overall well-being. Yet, it is often overlooked in traditional education systems. Here’s why mental health matters and how we can support it:</p>
                    <br/>
                    <h2 className="blog-title">The Connection Between Mental Health and Learning</h2>
                    <p className="blog-para-one">Students with good mental health are more focused, engaged, and resilient. Conversely, anxiety, depression, and stress can hinder concentration, memory, and motivation, impacting academic performance.</p>
                    <p className="blog-para-one">Promote Open Communication: Encourage students to talk about their feelings and provide a safe space for them to express themselves. Incorporate Mindfulness Practices: Activities like meditation, deep breathing, and yoga can reduce stress and enhance focus. Teach Time Management: Help students manage their workload effectively to avoid burnout. Offer Counseling Services: Schools should provide access to trained counselors who can support students’ mental health needs. Build a Positive School Culture: Foster an environment of inclusion, respect, and empathy to ensure students feel valued and supported.</p>
                    <br/>
                    <h2 className="blog-title">Recognizing the Signs of Struggle</h2>
                    <p className="blog-para-one">Educators and parents should be vigilant for signs of mental health struggles, such as changes in behavior, withdrawal, or declining academic performance. Early intervention can make a significant difference.</p>
                    <p className="blog-para-one">Mental health is as important as physical health in shaping a student’s future. By prioritizing it, we can empower students to reach their full potential both academically and personally.</p>
                    <br/>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Blogfour