import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Reforms () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Reforms</p>
                <p className="vis-para">Quest for boldness and change focusing on Socio, economic ,political and legislative reforms ,constitutional amendments.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">India's Push for Global Financial Reform</p>
                                <a className="edu-para-one" href="#">In an era marked by economic uncertainty and shifting power dynamics, India has emerged as a vocal proponent of global financial reform.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./reformsone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./refblogone.jpg" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">India’s Role in Global Climate Policy Reform</p>
                                <a className="edu-para-one" href="#">Climate change is a pressing global issue, and India has positioned itself as a key player in driving global climate policy reform.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./reformstwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./refblogtwo.jpg" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">India’s Advocacy for UN Security Council Reforms</p>
                                <a className="edu-para-one" href="#">The United Nations Security Council (UNSC), established in 1945, remains one of the most powerful bodies in global governance. </a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./reformsthree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./refblogthree.jpg" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">India’s Vision for Trade and Economic Reforms</p>
                                <a className="edu-para-one" href="#">As globalization evolves, India is championing trade and economic reforms that promote fairness and inclusivity. From advocating for equitable trade.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./reformsfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./refblogfour.jpg" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reforms