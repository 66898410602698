import React from "react";
import "./App.css"

function Footer () {
    return(
        <div>
            <div class="container-footer">
            <a href="./home"><img className="nav-logo foo" src="./fm.png" /></a>
                <hr className="line"></hr>
                <div class="row">
                    <div class="col">
                        <h5 className="footer-h">Sections</h5>
                        <a className="footer-links-gray" href="./news">News</a>
                        <br/>
                        <a className="footer-links-gray" href="./education">Education</a>
                        <br/>
                        <a className="footer-links-gray" href="./learning">Learning</a>
                        <br/>
                        <a className="footer-links-gray" href="./humor">Humor & Comics</a>
                        <br/>
                        <a className="footer-links-gray" href="./health">Health</a>
                    </div>
                    <div class="col">
                    <h5 className="footer-h-s">.</h5>
                        <a className="footer-links-gray" href="./polls">Opinon Polls</a>
                        <br/>
                        <a className="footer-links-gray" href="./reforms">Reforms</a>
                        <br/>
                        <a className="footer-links-gray" href="./visions">Vision for world</a>
                        <br/>
                        <a className="footer-links-gray" href="./guidens">Videos</a>
                        <br/>
                        <a className="footer-links-gray" href="./articles">Articles</a>
                    </div>
                    <div class="col">
                        <h5 className="footer-h">More</h5>
                        <a className="footer-links-gray" href="./role">Role Models</a>
                        <br/>
                        <a className="footer-links-gray">Subscribe</a>
                        <br/>
                        <a className="footer-links-gray">Shop</a>
                        <br/>
                        <a className="footer-links-gray">Buy Covers and Cartoons</a>
                    </div>
                    <div class="col">
                        <h5 className="footer-h-s">.</h5>
                        <a className="footer-links-gray">Digital Access</a>
                        <br/>
                        <a className="footer-links-gray">Newsletters</a>
                        <br/>
                        <a className="footer-links-gray">Jigsaw Puzzle</a>
                        <br/>
                        <a className="footer-links-gray">RSS</a>
                    </div>
                </div>
                <hr className="line"></hr>
                <div class="row">
                    <div class="col">
                        <a className="footer-links-gray" href="./about">About</a>
                        <br/>
                        <a className="footer-links-gray">Careers</a>
                        <br/>
                        <a className="footer-links-gray" href="./contact">Contact</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray">F.A.Q.</a>
                        <br/>
                        <a className="footer-links-gray">Media Kit</a>
                        <br/>
                        <a className="footer-links-gray">Press</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray">Accessibility Help</a>
                        <br/>
                        <a className="footer-links-gray">User Agreement</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray" href="./privacypolicy">Privacy Policy</a>
                        <br/>
                        <a className="footer-links-gray" href="./privacyright">Privacy Rights</a>
                    </div>
                </div>
                <hr className="line"></hr>
                <div class="row copy">
                    <div class="col-md-4">
                        <p>@2024 All Copy rights reserved to Times Quest</p>
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./facebook.jpg" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./x.png" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./insta.jpg" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon-you" src="./youtube.png" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer