import React from "react";

function World () {
    return(
        <div>
            <div class="container">
                <h3 className="todays-news text-center">LEARNING</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./edublogone.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">LEARNING</p>
                                    <a class="card-title-around" href="#">Mastering Time Management for Better Learning Outcomes.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Time management is a critical skill for successful learning. With proper planning and organization.</p>
                                    <a className="home-read-more-link" href="./learblogone" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./edublogtwo.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">LEARNING</p>
                                    <a class="card-title-around" href="#">The Power of Peer Learning: Why Collaboration Matters.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Learning doesn’t have to be a solitary endeavor. Peer learning, where students collaborate.</p>
                                    <a className="home-read-more-link" href="./learblogtwo" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three-le" src="./edublogthree.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">LEARNING</p>
                                    <a class="card-title-around" href="#">The Science Behind Effective Learning Techniques.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Learning is a fundamental skill, yet many of us don’t fully understand how to learn effectively.</p>
                                    <a className="home-read-more-link" href="./learblogthree" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three-lea" src="./edublogfour.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">LEARNING</p>
                                    <a class="card-title-around" href="#">Learning Beyond the Classroom: </a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">The digital revolution has transformed how we learn. Online education has made knowledge</p>
                                    <a className="home-read-more-link" href="./learblogfour" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <a href="./learning"><button className="view-all" >View All</button></a>
            </div>
            <br/>
            <div className="box-container">
                <div className="row">
                    <div className="col-md-4 four-cont-col">
                        <h4 className="fiction">Fiction</h4>
                        <h1 className="paris">“Paris Friend”</h1>
                        <img className="box-cont-img" src="./art.png" />
                        <br/>
                        <a className="box-font-name">By Shuang Xuetao</a>
                        <p className="box-font-para">Illustration by Joey Yu</p>
                    </div>
                    <div className="col">
                        <p className="box-font-info">​​Xiaoguo had a terror of thirst, so he kept a glass of water on the table beside his hospital bed. As soon as it was empty, he asked me to refill it. I wanted to warn him that this was unhealthy—guzzling water all night long puts pressure on the kidneys, and pissing that much couldn’t be good for his injury. He was tall, though, so I decided his insides could probably cope.</p>
                        <p className="box-font-info-two">​​Xiaoguo had a terror of thirst, so he kept a glass of water on the table beside his hospital bed. As soon as it was empty, he asked me to refill it. I wanted to warn him that this was unhealthy—guzzling water all night long puts pressure on the kidneys, and pissing that much couldn’t be good for his injury. He was tall, though, so I decided his insides could probably cope.</p>
                        <a className="continue-read">Continue Reading...</a>
                        <hr/>
                        <a className="week-in">This Week in Fiction</a>
                        <br/>
                        <a className="week-in">Shuang Xuetao on Memory as a Movie</a>
                        <br/>
                        <a className="week-in">All fiction</a>
                    </div>
                </div>
            </div>
            <br/>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">HEALTH</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./healthblogone.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">HEALTH - TIP FOR THE DAY</p>
                                    <a class="card-title-around" href="#">The Importance of Preventative Healthcare.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Preventative healthcare is a cornerstone of a healthy life. By taking proactive steps to maintain..</p>
                                    <a className="home-read-more-link" href="./healthone" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./healthblogtwo.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">HEALTH - TIP FOR THE DAY</p>
                                    <a class="card-title-around" href="#">Mental Health Matters: Breaking the Stigma.</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Mental health is as important as physical health, yet it often doesn’t receive the attention it deserves.</p>
                                    <a className="home-read-more-link" href="./healthtwo" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./healthblogthree.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">HEALTH - TIP FOR THE DAY</p>
                                    <a class="card-title-around" href="#">The Connection Between Nutrition and Health</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Nutrition plays a vital role in maintaining overall health. What we eat directly impacts our</p>
                                    <a className="home-read-more-link" href="./healththree" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./healthblogfour.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">HEALTH - TIP FOR THE DAY</p>
                                    <a class="card-title-around" href="#">The State of Healthcare in Andhra Pradesh:</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">As Andhra Pradesh gears up for the 2024 elections, healthcare has emerged as a pivotal issue...</p>
                                    <a className="home-read-more-link" href="./healthfour" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <a href="./health"><button className="view-all" >View All</button></a>
            </div>
            <br/>
            <hr/>

            <div className="bk-tr">
                <p className="trump-cmt" href="#">Comment</p>
                <p className="trump-title" href="#">STOPPING THE PRESSURE</p>
                <p className="trump-para" href="#">After spending years painting the media as the “enemy of the people,” Donald</p>
                <p className="trump-para-two">Trump is ready to intensify his battle against the journalists</p>
                <p className="trump-para-thr">who cover him.</p>
                <br/>
                <p className="trump-name" href="#">By David Remnick</p>
            </div>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">REFORMS</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./refblogone.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">India's Push for Global Financial Reform</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">In an era marked by economic uncertainty and shifting power dynamics, India has emerged as a..</p>
                                    <a className="home-read-more-link" href="./reformsone" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./refblogtwo.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">India’s Role in Global Climate Policy Reform</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Climate change is a pressing global issue, and India has positioned itself as a key player in driving...</p>
                                    <a className="home-read-more-link" href="./reformstwo" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./refblogthree.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">India’s Advocacy for UN Security Council Reforms</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">The United Nations Security Council (UNSC), established in 1945, remains one of the most powerful bodies</p>
                                    <a className="home-read-more-link" href="./reformsthree" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./refblogfour.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">India’s Vision for Trade and Economic Reforms</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">As globalization evolves, India is championing trade and economic reforms that promote fairness.</p>
                                    <a className="home-read-more-link" href="./reformsfour" class="name-name">Read more...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <a href="./reforms"><button className="view-all">View All</button></a>
            </div>
            <br/>
            <hr/>
        </div>
    )
}

export default World