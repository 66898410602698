import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Newsblogthree () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">How to Identify Fake News and Misinformation</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./newsblogthree.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">In the age of information, not all news is created equal. Fake news and misinformation can spread like wildfire, leading to confusion and mistrust. Here’s how to identify and combat false information:</p>
                    <br/>
                    <h2 className="blog-title">Check the Source</h2>
                    <p className="blog-para-one">Always verify the credibility of the source. Reputable news outlets follow strict journalistic standards and are transparent about their reporting processes.</p>
                    <p className="blog-para-one"></p>
                    <br/>
                    <h2 className="blog-title">Look for Supporting Evidence</h2>
                    <p className="blog-para-one">Reliable news stories are backed by evidence, including data, expert opinions, and multiple sources. Be wary of articles that make bold claims without proof.</p>
                    <p className="blog-para-one">Fake news often uses sensationalist language to provoke strong emotions. If a headline seems too outrageous or inflammatory, approach it with skepticism.</p>
                    <br/>
                    <h2 className="blog-title">Verify with Fact-Checking Websites</h2>
                    <p className="blog-para-one">Websites like Snopes, PolitiFact, and FactCheck.org can help you confirm the accuracy of questionable claims.</p>
                    <p className="blog-para-one">Before sharing news on social media, take a moment to assess its credibility. Spreading false information can have serious consequences.</p>
                    <p className="blog-para-one">By developing critical thinking skills and using reliable tools, we can combat misinformation and promote a healthier information ecosystem.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Newsblogthree