import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

function Newsblogone () {
    return (
        <div>
            <Navbar />
            <div className="row">
                <div className="col">
                    <h1 className="main-title-for-blog">Staying Updated in a Fast-Paced World: Why News Matters</h1>
                </div>
                <div className="col">
                    <img className="blog-one-main-img" src="./newsblogone.png" />
                </div>
            </div>
            <div className="row blog-info-sec">
                <div className="col-md-7">
                    <p className="blog-para-one">In today’s rapidly changing world, staying informed is more important than ever. News provides a window into current events, shaping our understanding of the world and our place within it. But how does consuming news contribute to personal and societal growth?</p>
                    <br/>
                    <h2 className="blog-title">Awareness of Global Issues</h2>
                    <p className="blog-para-one">From climate change to technological innovations, news helps us stay updated on critical global developments. This awareness enables individuals to make informed decisions and engage in meaningful conversations.</p>
                    <br/>
                    <h2 className="blog-title">Promotes Civic Engagement</h2>
                    <p className="blog-para-one">A well-informed citizenry is essential for a thriving democracy. News highlights social, economic, and political issues, encouraging people to vote, volunteer, and advocate for causes they care about.</p>
                    <br/>
                    <h2 className="blog-title">Encourages Critical Thinking</h2>
                    <p className="blog-para-one">Consuming news from diverse sources fosters critical thinking skills. It challenges readers to analyze information, recognize biases, and form well-rounded opinions.</p>
                    <p className="blog-para-one"><strong>Tips for Consuming News Responsibly</strong>
                        <br/>
                        * Follow reputable sources to avoid misinformation.
                        <br/>
                        * Diversify your news intake to understand different perspectives.
                        <br/>
                        * Set boundaries to prevent information overload.</p>
                    <p className="blog-para-one">By staying informed, we empower ourselves to be active participants in society, ready to tackle challenges and seize opportunities.</p>
                </div>
                <div className="col-md-3">
                    ads
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Newsblogone